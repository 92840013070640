import http from "../http-common";

class DepartmentDataService {
  getAll() {
    return http.get("/department/allDepartment");
  }

  get(id) {
    return http.get(`/department/${id}`);
  }

  create(data) {
    return http.post("/department/create", data);
  }

  update(id, data) {
    return http.put(`/department/${id}`, data);
  }

  delete(id) {
    return http.delete(`/department/${id}`);
  }

  deleteAll() {
    return http.delete(`/department`);
  }
}

export default new DepartmentDataService();