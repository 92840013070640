import http from "../http-common";

class PositionDataService {
  getAll() {
    return http.get("/position/allPosition");
  }

  get(id) {
    return http.get(`/position/${id}`);
  }

  create(data) {
    return http.post("/position/create", data);
  }

  update(id, data) {
    return http.put(`/position/${id}`, data);
  }

  delete(id) {
    return http.delete(`/position/${id}`);
  }

  deleteAll() {
    return http.delete(`/position`);
  }
}

export default new PositionDataService();