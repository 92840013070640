import PositionDataService from '../services/PositionDataService'
    const state = {
        designation: [],
    }
    const getters = {
      getPositions(state){
        return state.designation
      }
    }
    const mutations = {
      // ADD_DESIGNATION(state,designation){
      //   state.designation.push(designation);
      // }
      SET_POSITION(state, result){
        state.designation = result
      }
    }
    const actions = {
      async addDesignation({commit}, designation) {
        await PositionDataService.create(designation)
        .then(function(res){
          console.log(res);
        })
      },
      async fetchPosition({commit}) {
        await PositionDataService.getAll()
        .then(function (res) {
          commit('SET_POSITION',res.data);
        })
      },
      async fetchOnePosition({commit},id) {
        await PositionDataService.get(id)
        .then(function (res) {
          console.log(res);
        })
      },
      async deletePosition({commit},id) {
        await PositionDataService.delete(id)
        .then(function (res) {
          console.log(res);
        })
        
      },
      async updatePosition({commit},{id,positionData}) {
        await PositionDataService.update(id,positionData)
        .then(function (res) {
          console.log(res);
        })
        
      }
    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}