import CompanyDataService from '../services/CompanyDataService'
    const state = {
        company: [],
    }
    const getters = {
    }
    const mutations = {
      SET_COMPANY(state,result){
        state.company = result
      }
    }
    const actions = {
      async getCompany({commit}) {
        await CompanyDataService.getAll()
        .then(function (res) {
          commit('SET_COMPANY',res.data)
        })
      },
      async updateCompany({commit},{id,companyData}) {
        await CompanyDataService.update(id,companyData)
        .then(function (res) {
          console.log(res);
        })
      }
    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}