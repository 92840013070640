
    const state = {
        headers: [
          { text: 'Type',value: 'type'},
          { text: 'Rate', value: 'rate' },
        ],
        allowance: [],
    }
    const getters = {
      sumAllowance(){
        var result = state.allowance.map(function (x) { 
          return parseInt(x, 10); 
        });
        const sum = result.reduce((accumulator, value) => {
          return accumulator + value;
        }, 0);
        return sum;
      },
    }
    const mutations = {
      ADD_ALLOWANCE(state,allowance){
        state.allowance.push(allowance);
      },
      CLEAR_ALLOWANCE(state){
        state.allowance.splice(0,state.allowance.length)
      }
    }
    const actions = {
      addAllowance({commit}, allowance) {
        commit('ADD_ALLOWANCE',allowance);
      },
      clearAllowance({commit}) {
        commit('CLEAR_ALLOWANCE');
      }
    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}