import BranchDataService from '../services/BranchDataService'
    const state = {
        branch: [],
    }
    const getters = {
      getBranchs(state){
        return state.branch
      }
    }
    const mutations = {
      // ADD_BRANCH(state,location){
      //   state.branch.push(location);
      // },
      SET_BRANCH(state, result){
        state.branch = result
      }
    }
    const actions = {
      async addBranch({commit}, location) {
        await BranchDataService.create(location)
          .then(function(res){
            console.log(res);
          })
      },
      async fetchBranch({commit}) {
        await BranchDataService.getAll()
        .then(function (res) {
          commit('SET_BRANCH',res.data);
        })
      },
      async fetchOneBranch({commit},id) {
        await BranchDataService.get(id)
        .then(function (res) {
          console.log(res);
        })
      },
      async deleteBranch({commit},id) {
        await BranchDataService.delete(id)
        .then(function (res) {
          console.log(res);
        })
        
      },
      async updateBranch({commit},{id,branchData}) {
        await BranchDataService.update(id,branchData)
        .then(function (res) {
          console.log(res);
        })
        
      }

    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}