import http from "../http-common";

class PayrollDataService {
  getAll() {
    return http.get("/payroll/allPayroll");
  }
  getAllPermanentPayrollHistory() {
    return http.get("/payroll/summaryPermanentPayrollHistory");
  }
  getAllLaborPayrollHistory() {
    return http.get("/payroll/summaryLaborPayrollHistory");
  }
  getAllPermanent() {
    return http.get("/payroll/allPermanentPayroll");
  }
  getPaymentSummary(data){
    return http.post("/payroll/permanentPayrollSummary",data);
  }
  getAllLabor() {
    return http.get("/payroll/allLaborPayroll");
  }
  getAllPreviousPayroll(data) {
    return http.get("/payroll/allPerviousMonthPermanentPayroll",data);
  }
  getLessWorkingDays() {
    return http.get("/payroll/allLessWorkingDays");
  }
  getLessWorkingDaysPayrollHistory(data) {
    return http.post("/payroll/allLessWorkingDaysPayrollHistory",data);
  }
  getPreviousMonthTotalBasicSalary(data){
    return http.post("/payroll/sumOfPreviousPayrollHistory",data);
  }
  getPayrollHistory(){
    return http.get("/payroll/allPayrollHistory");
  }
  getTotalPermanentPayrollHistory(data){
    return http.post("/payroll/sumPermanentPayrollHistory",data);
  }
  getTotalSumPermanentPayrollHistory(){
    return http.get("/payroll/totalSumPermanentPayrollHistory");
  }
  getTotalSumLaborPayrollHistory(){
    return http.get("/payroll/totalSumLaborPayrollHistory");
  }
  getTotalLaborPayrollHistory(data){
    return http.post("/payroll/sumLaborPayrollHistory",data);
  }
  create(data) {
    return http.post("/payroll/create", data);
  }
  getPayrollHistory(data) {
    return http.post("/payroll/getPayrollHistory", data);
  }
  getPermanentPayrollHistory(data) {
    return http.post("/payroll/getPermanentPayrollHistory", data);
  }
  getLaborPayrollHistory(data) {
    return http.post("/payroll/getLaborPayrollHistory", data);
  }
  createBackup() {
    return http.post("/payroll/createPayrollHistory");
  }

  update(id, data) {
    return http.put(`/payroll/${id}`, data);
  }

//   delete(id) {
//     return http.delete(`/branch/${id}`);
//   }

  deleteAllPayroll() {
    return http.delete(`/payroll`);
  }
}

export default new PayrollDataService();