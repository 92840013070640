import http from "../http-common";

class CompanyDataService {
  getAll() {
    return http.get("/company/getCompany");
  }

  update(id, data) {
    return http.put(`/company/${id}`, data);
  }
}

export default new CompanyDataService();