import http from "../http-common";

class BranchDataService {
  getAll() {
    return http.get("/branch/allBranch");
  }

  get(id) {
    return http.get(`/branch/${id}`);
  }

  create(data) {
    return http.post("/branch/create", data);
  }

  update(id, data) {
    return http.put(`/branch/${id}`, data);
  }

  delete(id) {
    return http.delete(`/branch/${id}`);
  }

  deleteAll() {
    return http.delete(`/branch`);
  }
}

export default new BranchDataService();