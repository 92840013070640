import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueHtmlToPaper from 'vue-html-to-paper';
import Vue2Filters from 'vue2-filters'
import excel from 'vue-excel-export'
import OneSignalVue from 'onesignal-vue'

Vue.use(OneSignalVue);


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Vue2Filters)
Vue.use(excel)

import VueSimplePrintPlugin from 'vue-simple-print';
Vue.use(VueSimplePrintPlugin);

Vue.config.productionTip = false
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=no'
  ],
  styles: [
    'https://unpkg.com/bootstrap/dist/css/bootstrap.min.css',
    'https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.css',
    'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
    'https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css',
    'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900',
    'https://takesaas.com/customStyle.css'
    
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper,options);
new Vue({
  router,
  store,
  vuetify,
  beforeMount() {
    this.$OneSignal.init({ appId: 'e9aa50e0-7474-45dc-8552-aba08668835e' });
  },
  render: h => h(App)
}).$mount('#app')
