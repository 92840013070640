import UserDataService from '../services/UserDataService'
    const state = {
        headers: [
            { text: 'Username',align: 'start',value: 'username'},
            // { text: 'Email', value: 'email' },
            { text: 'Password', value: 'password' },
            { text: 'Role', value: 'role' },
            { text: 'Actions', value: 'actions', sortable: false },
          ],
        user: [],
    }
    const getters = {
    //   getOvertimes(state){
    //     return state.overtime
    //   }
    }
    const mutations = {
      
      SET_USER(state,result){
        state.user = result
      }
    }
    const actions = {
      async addUser({commit}, user) {
        await UserDataService.create(user)
        .then(function(res){
          console.log(res);
        })
      },
      async fetchUser({commit}) {
        await UserDataService.getAll()
        .then(function (res) {
          commit('SET_USER',res.data);
        })
      },
    //   async fetchOneOvertime({commit},id) {
    //     await OvertimeDataService.get(id)
    //     .then(function (res) {
    //       console.log(res);
    //     })
    //   },
      async deleteUser({commit},id) {
        await UserDataService.delete(id)
        .then(function (res) {
          console.log(res);
        })
        
      },
      async updateUser({commit},{id,user}) {
        await UserDataService.update(id,user)
        .then(function (res) {
          console.log(res);
        })
        
      }
    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}