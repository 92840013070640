import OvertimeDataService from '../services/OvertimeDataService'
    const state = {
        overtime: [],
    }
    const getters = {
      getOvertimes(state){
        return state.overtime
      }
    }
    const mutations = {
      // ADD_OVERTIME(state,overtime){
      //   state.overtime.push(overtime);
      // }
      SET_OVERTIME(state, result){
        state.overtime = result
      }
    }
    const actions = {
      async addOvertime({commit}, overtime) {
        await OvertimeDataService.create(overtime)
        .then(function(res){
          console.log(res);
        })
      },
      async fetchOvertime({commit}) {
        await OvertimeDataService.getAll()
        .then(function (res) {
          commit('SET_OVERTIME',res.data);
        })
      },
      async fetchOneOvertime({commit},id) {
        await OvertimeDataService.get(id)
        .then(function (res) {
          console.log(res);
        })
      },
      async deleteOvertime({commit},id) {
        await OvertimeDataService.delete(id)
        .then(function (res) {
          console.log(res);
        })
        
      },
      async updateOvertime({commit},{id,overtimeData}) {
        await OvertimeDataService.update(id,overtimeData)
        .then(function (res) {
          console.log(res);
        })
        
      }
    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}