import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
function guardMyroute(to, from, next)
{
  var isAuthenticated= false

  if(localStorage.getItem('token'))
    isAuthenticated = true;
   else
    isAuthenticated= false;
  if(isAuthenticated) 
  {
    console.log(`authenticated user status is ${isAuthenticated}`);
    next(); // allow to enter route
  } 
  else
  {
    next('/'); // go to '/login';
  }
}


const routes = [
  {
    path: "/",
    name: "login",
    
    component: () =>
      import("../components/Login/Login.vue"),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter : guardMyroute,
    component: () => import('../components/Dashboard/Dashboard.vue')
  },
  {
    path: '/employee',
    name: 'Employee',
    beforeEnter : guardMyroute,
    component: () => import('../components/Employee/Employee.vue')
  },
  {
    path: '/department',
    name: 'Department',
    beforeEnter : guardMyroute,
    component: () => import('../components/Department/Department.vue')
  },
  {
    path: '/payroll',
    name: 'Payroll Report',
    beforeEnter : guardMyroute,
    component: () => import('../components/Payroll/Payroll.vue')
  },
  {
    path: '/payrollComparision',
    name: 'Payroll Comaprision',
    beforeEnter : guardMyroute,
    component: () => import('../components/Payslip/Tables/Report/Payrollsheet/PayrollComparison.vue')
  },
  {
    path: '/payslip',
    name: 'Payroll Calculation',
    beforeEnter : guardMyroute,
    component: () => import('../components/Payslip/Container/Payslip.vue')
  },
  {
    path: '/payrollHistory',
    name: 'Payroll History Summary',
    beforeEnter : guardMyroute,
    component: () => import('../components/Payslip/PayrollHistory/PayrollHistory.vue')
  },
  {
    path: '/report',
    name: 'Report',
    beforeEnter : guardMyroute,
    component: () => import('../components/Report/Report.vue')
  },
  {
    path: '/users',
    name: 'Users',
    beforeEnter : guardMyroute,
    component: () => import('../components/Users/Users.vue')
  },
  {
    path: '/setting',
    name: 'Configuration',
    beforeEnter : guardMyroute,
    component: () => import('../components/Setting/CompanySetting.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
