import http from "../http-common";

class EmployeeDataService {
  getAll() {
    return http.get("/employee/allEmployee");
  }

  getNewEmployee(payload) {
    console.log("from the service");
    console.log(payload);
    return http.post("/employee/allNewEmployee", payload);
  }
  getResignedEmployee(payload) {
    console.log("from the service");
    console.log(payload);
    return http.post("/employee/allResignedEmployee", payload);
  }
  getSalaryIncrement(payload) {
    return http.post("/employee/allSalaryIncrement", payload);
  }
  getAllSalaryIncrement(){
    return http.get("/employee/findAllSalaryIncrement");
  }
  getResignedEmployees(){
    return http.get("/employee/findAllResignedEmployee");
  }
  get(id) {
    return http.get(`/employees/${id}`);
  }

  create(data) {
    return http.post("/employee/create", data);
  }
  createSalaryIncrement(data) {
    return http.post("/employee/createSalaryIncrement", data);
  }

  update(id, data) {
    return http.put(`/employees/${id}`, data);
  }

  delete(id) {
    return http.delete(`/employees/${id}`);
  }
  employeeResign(id, data) {
    return http.delete(`/employees/resignedEmployee/${id}/${data}`);
  }
  deleteAll() {
    return http.delete(`/employees`);
  }
}

export default new EmployeeDataService();