
    const state = {
        // headers: [
        //   { text: 'Type',value: 'type'},
        //   { text: 'Rate', value: 'rate' },
        // ],
        payslip: [
            {
              full_name: 'Kebede Arega',
              phone: '0963254876',
              workType: 'Permanent',
              Account_No: '100034342367',
              postionAllowance: 0,
              transportAllowance: 0,
              allowance: 200,
              expectedWorkingDays: 30,
              grossSalary: 7300,
              netSalary: 0,
              department: 'IT',
              updated: 0,
  
            },
            {
              full_name: 'Solomon Tadese',
              phone: '0956897412',
              workType: 'Daily-Labor',
              Account_No: '100034342367',
              postionAllowance: 250,
              transportAllowance: 150,
              allowance: 200,
              expectedWorkingDays: 30,
              dailyWage: 137.40,
              grossSalary: 0,
              netSalary: 0,
              department: 'IT',
              updated: 0,
            },
            {
              full_name: 'Abbebe Tefera',
              phone: '0989784563',
              workType: 'Permanent',
              Account_No: '100034342367',
              postionAllowance: 250,
              transportAllowance: 150,
              allowance: 200,
              expectedWorkingDays: 30,
              grossSalary: 7800,
              netSalary: 0,
              department: 'IT',
              updated: 0,
            },
            
          ],
    }
    const getters = {
    }
    const mutations = {
      ADD_PAYSLIP(state,payslip){
        state.payslip.push(payslip);
      }
    }
    const actions = {
      addPayslip({commit}, payslip) {
        commit('ADD_PAYSLIP',payslip);
      }
    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}