import http from "../http-common";

class BranchDataService {
  getAll() {
    return http.get("/user/allUser");
  }

  get(id) {
    return http.get(`/branch/${id}`);
  }

  create(data) {
    return http.post("/auth/signup", data);
  }

  update(id, data) {
    return http.put(`/auth/${id}`, data);
  }

  delete(id) {
    return http.delete(`/user/${id}`);
  }

  deleteAll() {
    return http.delete(`/branch`);
  }
}

export default new BranchDataService();