import http from "../http-common";

class ReportDataService {

  getAllReport(data) {
    return http.post("/payroll/getGeneralReport", data);
  }
  getAllPermanentReport(data) {
    return http.post("/payroll/getPermanentReport", data);
  }
  getOnlyAllPermanentReport(data) {
    return http.post("/payroll/getOnlyPermanentReport", data);
  }
  getPermanentTemporaryReport(data) {
    return http.post("/payroll/getPermanentTemporaryReport", data);
  }
  
}

export default new ReportDataService();