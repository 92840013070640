import DepartmentDataService from '../services/DepartmentDataService'
    const state = {
        headers: [
          { text: 'Department',align: 'start',value: 'name'},
          { text: 'Tototal Employee', value: 'total' },
          { text: 'Description', value: 'description' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        department: [],
    }
    const getters = {
      getDepartments(state){
        return state.department
      }
    }
    const mutations = {
      // ADD_DEPARTMENT(state,department){
      //   state.department.push(department);
      // }
      SET_DEPARTMENT(state, result){
        state.department = result
      }
    }
    const actions = {
      async addDepartment({commit}, department) {
        await DepartmentDataService.create(department)
        .then(function(res){
          console.log(res);
        })
      },
      async fetchDepartment({commit}) {
        await DepartmentDataService.getAll()
        .then(function (res) {
          commit('SET_DEPARTMENT',res.data);
        })
      },
      async fetchOneDepartment({commit},id) {
        await DepartmentDataService.get(id)
        .then(function (res) {
          console.log(res);
        })
      },
      async deleteDepartment({commit},id) {
        await DepartmentDataService.delete(id)
        .then(function (res) {
          console.log(res);
        })
        
      },
      async updateDepartment({commit},{id,departmentData}) {
        await DepartmentDataService.update(id,departmentData)
        .then(function (res) {
          console.log(res);
        })
        
      }
    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}