import http from "../http-common";

class authService {
  create(data) {
    return http.post("/auth/signup", data);
  }
  loginUser(data){
    return http.post("/auth/signin", data);
  }
}

export default new authService();