import EmployeeDataService from '../services/EmployeeDataService'
    const state = {
        headers: [
          { text: '#',align: 'start',value: 'id'},
          { text: 'Full Name',value: 'name'},
          // { text: 'Phone No', value: 'phone' },
          // { text: 'Email', value: 'email' },
          // { text: 'City', value: 'city' },
          { text: 'Tin NO', value: 'tin' },
          // { text: 'House No', value: 'houseNo' },
          // { text: 'Birth Date', value: 'birthDay' },
          { text: 'Position', value: 'position' },
          { text: 'Department', value: 'department' },
          { text: 'Branch', value: 'branch' },
          { text: 'Work Type', value: 'workType' },
          { text: 'Hiring Date', value: 'hiringDate' },
          // { text: 'Gross Salary', value: 'grossSalary' },
          // { text: 'Expecting Working Days', value: 'expectedWorkingDays' },
          // { text: 'Position Allowance', value: 'positionAllowance' },
          // { text: 'Transport Allowance', value: 'transportAllowance' },
          { text: 'Bank Account', value: 'accountNo' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        
        employees: [],
        newEmployees:[],
        resignedEmployee: [],
        salaryIncrement: [],
        allSalaryIncrement:[],
        allResignedEmployess:[]
        
    }
    const getters = {
      employeeList(state){
        return state.employees
      },
      getEmployees(state){
        return state.employees
      }
    }
    const mutations = {
      addEmployee(state, employee) {
        state.employee.push(employee);
      },
      SET_EMPLOYEE(state, result){
        state.employees = result
      },
      SET_NEW_EMPLOYEE(state,result){
        state.newEmployees = result
      },
      SET_RESIGNED_EMPLOYEE(state,result){
        state.resignedEmployee = result
      },
      SET_SALARY_INCREMENT_EMPLOYEE(state,result){
        state.salaryIncrement = result
      },
      SET_ALL_SALARY_INCREMENT(state,result){
        state.allSalaryIncrement = result
      },
      SET_ALL_RESIGNED_EMPLOYEE(state,result){
        state.allResignedEmployess = result
      }
    }
    const actions = {
      async addEmployee({commit}, employee) {
        await EmployeeDataService.create(employee)
        .then(function (res) {
          // commit('addEmployee',employee);
        })
        
      },
      async addSalaryIncrement({commit}, payload) {
        console.log(payload);
        await EmployeeDataService.createSalaryIncrement(payload)
        .then(function (res) {
          // commit('addEmployee',employee);
        })
        
      },
      async fetchEmployee({commit}) {
        await EmployeeDataService.getAll()
        .then(function (res) {
          commit('SET_EMPLOYEE',res.data);
        })
      },
      async fetchNewEmployee({commit},payload) {
        await EmployeeDataService.getNewEmployee(payload)
        .then(function (res) {
          commit('SET_NEW_EMPLOYEE',res.data);
        })
      },
      async fetchResignedEmployee({commit},payload) {
        await EmployeeDataService.getResignedEmployee(payload)
        .then(function (res) {
          commit('SET_RESIGNED_EMPLOYEE',res.data);
        })
      },
      async fetchSalaryIncrement({commit},payload) {
        await EmployeeDataService.getSalaryIncrement(payload)
        .then(function (res) {
          commit('SET_SALARY_INCREMENT_EMPLOYEE',res.data);
        })
      },
      async fetchAllSalaryIncrement({commit}) {
        await EmployeeDataService.getAllSalaryIncrement()
        .then(function (res) {
          commit('SET_ALL_SALARY_INCREMENT',res.data);
        })
      },
      async fetchAllResignedEmployees({commit}) {
        await EmployeeDataService.getResignedEmployees()
        .then(function (res) {
          commit('SET_ALL_RESIGNED_EMPLOYEE',res.data);
        })
      },
      async fetchLessWorkingDays({commit}) {
        await EmployeeDataService.getLessWorkingDays()
        .then(function (res) {
          commit('SET_LESS_WORKING_DAYS',res.data);
        })
      },
      async fetchOneEmployee({commit},id) {
        await EmployeeDataService.get(id)
        .then(function (res) {
          console.log(res);
          // commit('SET_ONE_EMPLOYEE',res.data);
        })
      },
      async deleteEmployee({commit},id) {
        await EmployeeDataService.delete(id)
        .then(function (res) {
          console.log("Delete is Done!");
          // commit('SET_EMPLOYEE',res.data);
        })
      },
      async employeeResign({commit},{id,resignedEmployeeData}) {
        await EmployeeDataService.employeeResign(id,resignedEmployeeData.resignedDate)
        .then(function (res) {
          console.log("Resign is Done!");
          // commit('SET_EMPLOYEE',res.data);
        })
      },
      async updateEmployee({commit},{id,employeeData}) {
        await EmployeeDataService.update(id,employeeData)
        .then(function (res) {
          console.log(res);
          console.log("Update is Done!");
          // commit('SET_EMPLOYEE',res.data);
        })
      }
    }
export default {
    namespaced: true,    
    state,
    getters,
    actions, 
    mutations
}